import logo from './logo.svg';
import './App.css';

function App() {
  return (

      <div className="App">
          <div className="container">
              <h1 className="mt-5 mb-5">Amazonランキング定期便はサービスを終了しました。</h1>
              <p className="lead">Amazonランキング定期便をご利用いただきありがとうございました。</p>
              <p className="mb-5">今後ともデザインエッグをよろしくお願いします。</p>
              <div className="container text-center mb-5">
                  <div className="row">
                      <div className="col-sm-4 offset-sm-4">
                          <a href='https://designegg.co.jp/service/' target="_blank">
                            <img src="/designegg.png" className="w-100" />
                          </a>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-6 col-sm-3 mb-5">
                      <p>紙も電子書籍も出版できる「パブー」</p>
                      <a href='https://puboo.jp/' target="_blank">
                          <img src="/puboo.svg" class="w-100"/>
                      </a>
                  </div>
                  <div className="col-6 col-sm-3 mb-5">
                      <p>4980円で紙の本が出版できる「MyISBN」</p>
                      <a href='https://myisbn.jp/' target="_blank">
                          <img src="/myisbn.png" class="w-100"/>
                      </a>
                  </div>
                  <div className="col-6 col-sm-3 mb-5">
                      <p>0円で紙の本が出版できる「ムゲンブックス」</p>
                      <a href='https://mugenbooks.com/' target="_blank">
                          <img src="/mugenbooks.png" class="w-100"/>
                      </a>
                  </div>
                  <div className="col-6 col-sm-3 mb-5">
                      <p>プロに表紙画像の制作をを依頼できる「MyCover」</p>
                      <a href='https://mycover.jp/' target="_blank">
                          <img src="/mycover.png" id='mycover' />
                      </a>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default App;
